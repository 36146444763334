export default [
  {
    path: '/userinfoCard',
    name: 'userinfoCard',
    component: () => import('@/views/userinformation/Information-card'),
    meta: {
      title: '用户信息卡片',
      requiresAuth: false
    }
  }
]
