import Vue from 'vue'
import Router from 'vue-router'
import routerConfig from './router-table'
import store from '../store/index'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: routerConfig
})

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title
  if (title) {
    document.title = title
  }
  let isLogin = store.state.home.isLogin.value
  // console.log(isLogin)
  if (to.matched.some(record => record.meta.requiresAuth) && (!isLogin)) {
    next({
      path: '/',
      query: {
        redirect: to.fullPath
      }
    })
  }
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
export default router
