export default [
  {
    path: '/membersinvitation',
    name: 'membersinvitation',
    component: () => import('@/views/members/members-invitation'),
    meta: {
      title: '共创成员',
      requiresAuth: true
    }
  },
  {
    path: '/memberschapter',
    name: 'memberschapter',
    component: () => import('@/views/members/members-chapter'),
    meta: {
      title: '成员共创章节',
      requiresAuth: true
    }
  }
]
