import axios from 'axios'
import store from '../store'
import router from '../router'
// axios 配置
axios.defaults.timeout = 5000
// axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.baseURL = '/ugc'

let tokenLock = false
let tokenTime = 0
// http request 拦截器
// axios.interceptors.request.use(
//   config => {
//     let token = store.state.home.currentUser.UserToken
//     config.headers['Authorization'] = 'Bearer ' + token
//     return config
//   },
//   err => {
//     return Promise.reject(err)
//   })
axios.interceptors.request.use(config => {
  if (store.state.home.isLogin.value) {
    // config.headers.Authorization = 'bearer ' + store.state.users.currentUser.UserToken
    if (config.url !== '/oauth/token') {
      return checkToken(config).then(() => {
        let token = store.state.home.currentUser.UserToken
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token
        }
        return Promise.resolve(config)
      })
    }
  }
  return config
},
err => {
  return Promise.reject(err)
})
function checkToken (config) {
  var p = new Promise(function (resolve, reject) {
    if (resolve === void (0)) return
    let now = new Date().getTime()
    let expires = store.state.home.currentUser.Expires
    // console.log('checkToken ' + now + ' ' + expires + ' ' + (now - expires))
    // console.log('checkToken ' + (now - (expires - (60 * 1000 * 60 * 2))))
    if (now >= (expires - (60 * 1000 * 60 * 2))) {
      // console.log('checkToken refreshing')
      let RefreshToken = store.state.home.currentUser.RefreshToken
      if (RefreshToken) {
        // console.log(RefreshToken)
        // 正在请求更新token时，其他接口等待
        if (tokenLock && tokenTime < 30) {
          setTimeout(function () {
            tokenTime++
            checkToken().then(resolve)
          }, 500)
        } else if (tokenTime > 30) { // 500*30是15S，15s没有响应就直接跳登录页
          store.commit('home/clearUser')
          window.location.href = '/'
        } else {
          tokenLock = true
          axios({
            method: 'post',
            url: '/oauth/token',
            params: {
              'refresh_token': RefreshToken,
              'grant_type': 'refresh_token',
              'scope': 'all'
            },
            auth: {
              username: 'client',
              password: 'secret'
            }
          }).then(function (response) {
            tokenLock = false
            tokenTime = 0
            // console.log(response.data.access_token)
            var nowtime = new Date().getTime()
            /* eslint-disable */
            if (response.data != null && response.data.access_token != null) {
              store.commit('home/setToken', {
                'userToken': response.data.access_token,
                'refreshToken': response.data.refresh_token,
                'expires': response.data.expires_in * 1000 + nowtime
              })
            }
            resolve()
          }).catch((error) => {
            // console.log(error)
            reject(error)
          })
        }
      } else {
        resolve()
      }
    } else {
      resolve()
    }
  })
  return p
}
// http response 拦截器
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 401 清除token信息并跳转到登录页面
          store.commit('home/clearUser')
          // store.home.commit('users/clearUser')
          router.replace({
            path: '/',
            query: { redirect: router.currentRoute.fullPath }
          })
          break
        case 403:
          // 403 无权限，跳转到首页
          router.replace({
            path: '/book',
            query: { redirect: router.currentRoute.fullPath, }
          })
          break
      }
    }
    // console.log(JSON.stringify(error));//console : Error: Request failed with status code 402
    return Promise.resolve(error)
  })

export default axios
