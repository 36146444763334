export default [
  {
    path: '/invitedshare',
    name: 'invitedshare',
    component: () => import('@/views/share/Invited-share'),
    meta: {
      title: '邀请分享',
      requiresAuth: false
    }
  }
]
