import axios from 'axios'
const debug = process.env.NODE_ENV !== 'production'

export default {
  strict: debug,
  namespaced: true,
  state: {
    count: 0,
    // coverindex: 0,
    currentUser: {
      get UserName () {
        return sessionStorage.getItem('currentUser_name')
      },
      get UserToken () {
        return localStorage.getItem('currentUser_token')
      },
      get RefreshToken () {
        return localStorage.getItem('currentUser_refresh_token')
      },
      get Expires () {
        return localStorage.getItem('expires')
      }
    },
    isLogin: {
      get value () {
        // let isLogin = sessionStorage.getItem('isLogin')
        let isLogin = localStorage.getItem('isLogin')
        return isLogin === 'true'
      }
    }
  },
  getters: {},
  mutations: {
    increment (state) {
      state.count += 1
    },
    setUser (state, { userName, userToken, refreshToken, expires }) {
      // 在这里把用户名和token保存起来
      sessionStorage.setItem('currentUser_name', userName)
      localStorage.setItem('currentUser_token', userToken)
      localStorage.setItem('currentUser_refresh_token', refreshToken)
      let now = new Date().getTime()
      let expiresTime = now + expires * 1000
      // console.log(expires)
      localStorage.setItem('expires', expiresTime)
      localStorage.setItem('logintime', new Date().getTime())
      localStorage.setItem('isLogin', 'true')
    },
    setToken (state, { userToken, refreshToken, expires }) {
      localStorage.setItem('currentUser_token', userToken)
      localStorage.setItem('currentUser_refresh_token', refreshToken)
      localStorage.setItem('expires', expires)
    },
    clearUser (state) {
      sessionStorage.clear()
      localStorage.clear()
    }
  },
  actions: {
    loginTest (context, { username, password, token, router, type, userdata, toast }) {
      axios({
        method: 'post',
        url: '/oauth/token',
        params: {
          'username': username,
          // 'uuid': 'oh5bY1PEiLWcbXz_sZzkcyJRQDZI',
          'password': password,
          'token': token,
          'grant_type': 'password',
          'scope': 'all'
        },
        auth: {
          username: 'client',
          password: 'secret'
        }
      }).then(function (response) {
        // console.log(response)
        // alert(response.data.expires_in)
        context.commit('setUser', {
          'userName': username,
          'userToken': response.data.access_token,
          'refreshToken': response.data.refresh_token,
          'expires': response.data.expires_in
        })
        if (type === 'app') {
          // window.location.href = '/course'
          // localStorage.setItem('lwtId', 1)
          localStorage.setItem('lwtId', userdata.lwtId)
          axios({
            method: 'get',
            url: '/v1/getAgreeLog',
            params: {
              lwtId: localStorage.getItem('lwtId')
            }
          }).then((res) => {
            if (res.data.content.status === 0) {
              window.location.href = '/course'
            } else {
              window.location.href = '/book'
            }
          })
        } else {
          // window.location.href = '/course?lwtId=1'
          window.location.href = '/book'
        }
      }).catch(function (error) {
        if (error) {
          // console.log(error.stack)
        }
        toast('用户名密码错误')
      })
    },
    loginCode (context, { username, smscode, sUserId, params, token, router, type, userdata, toast }) {
      axios({
        method: 'post',
        url: '/oauth/token',
        params: {
          'username': username,
          // 'uuid': 'oh5bY1PEiLWcbXz_sZzkcyJRQDZI',
          'smscode': smscode,
          'sUserId': sUserId,
          'params': params,
          'token': token,
          'grant_type': 'sms_code',
          'scope': 'all'
        },
        auth: {
          username: 'client',
          password: 'secret'
        }
      }).then(function (response) {
        // console.log(response)
        // alert(response.data.expires_in)
        context.commit('setUser', {
          'userName': username,
          'userToken': response.data.access_token,
          'refreshToken': response.data.refresh_token,
          'expires': response.data.expires_in
        })
        if (type === 'app') {
          // window.location.href = '/course'
          // localStorage.setItem('lwtId', 1)
          localStorage.setItem('lwtId', userdata.lwtId)
          axios({
            method: 'get',
            url: '/v1/getAgreeLog',
            params: {
              lwtId: localStorage.getItem('lwtId')
            }
          }).then((res) => {
            if (res.data.content.status === 0) {
              window.location.href = '/course'
            } else {
              window.location.href = '/book'
            }
          })
        } else {
          // window.location.href = '/course?lwtId=1'
          window.location.href = '/userinfoCard'
        }
      }).catch(function (error) {
        if (error) {
          // console.log(error.stack)
        }
        toast('手机号或验证码错误')
      })
    },
    loginCode1 (context, { username, smscode, params, token, router, type, userdata, toast }) {
      axios({
        method: 'post',
        url: '/oauth/token',
        params: {
          'username': username,
          // 'uuid': 'oh5bY1PEiLWcbXz_sZzkcyJRQDZI',
          'smscode': smscode,
          'params': params,
          'token': token,
          'grant_type': 'sms_code',
          'scope': 'all'
        },
        auth: {
          username: 'client',
          password: 'secret'
        }
      }).then(function (response) {
        // console.log(response)
        // alert(response.data.expires_in)
        context.commit('setUser', {
          'userName': username,
          'userToken': response.data.access_token,
          'refreshToken': response.data.refresh_token,
          'expires': response.data.expires_in
        })
        if (type === 'app') {
          // window.location.href = '/course'
          // localStorage.setItem('lwtId', 1)
          localStorage.setItem('lwtId', userdata.lwtId)
          axios({
            method: 'get',
            url: '/v1/getAgreeLog',
            params: {
              lwtId: localStorage.getItem('lwtId')
            }
          }).then((res) => {
            if (res.data.content.status === 0) {
              window.location.href = '/course'
            } else {
              window.location.href = '/book'
            }
          })
        } else {
          // window.location.href = '/course?lwtId=1'
          window.location.href = '/book'
        }
      }).catch(function (error) {
        if (error) {
          // console.log(error.stack)
        }
        toast('手机号或验证码错误')
      })
    }
    // loginApp (context, { userData, router }) {
    //   context.commit('setUser', { 'userName': userData.userName, 'userToken': userData.userToken })
    //   // router.push({ path: '/book' })
    //   // router.push({ path: '/course', query: { lwtId: userData.lwtId } })
    //   // console.log('登录验证app登录')
    // }
  },
  modules: {}
}
