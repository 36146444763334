export default [
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about'),
    meta: {
      title: 'about'
    },
    children: [
      {
        path: 'demo',
        meta: {
          title: 'demo'
        },
        component: () => import('@/views/about-demo')
      }
    ]
  }
]
