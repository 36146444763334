export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/agree',
    name: 'agree',
    component: () => import('@/views/agreement'),
    meta: {
      title: '协议'
    }
  },
  {
    path: '/jumpLogin',
    name: 'jumpLogin',
    component: () => import('@/views/login-method/Jump-login'),
    meta: {
      title: '跳转方式登录'
    }
  }
  // {
  //   path: '/pw',
  //   name: '重设密码',
  //   component: () => import('@/views/password'),
  //   meta: {
  //     title: 'password'
  //   }
  // }
]
