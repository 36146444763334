export default [
  {
    path: '/bookCover',
    name: 'bookCoverList',
    component: () => import('@/views/book/book-coverlist'),
    meta: {
      title: '添加封面',
      requiresAuth: true
    }
  },
  {
    path: '/bookEdit',
    name: 'bookEdit',
    component: () => import('@/views/book/book-edit'),
    meta: {
      title: '创建作品',
      requiresAuth: true
    }
  }
]
