export default [
  {
    path: '/chapterAdd',
    name: 'chapterAdd',
    component: () => import('@/views/chapter/chapter-add'),
    meta: {
      title: '章节添加',
      requiresAuth: true
    }
  },
  {
    path: '/chapterCon',
    name: 'chapterCon',
    component: () => import('@/views/chapter/chapter-con'),
    meta: {
      title: '章节名称',
      requiresAuth: true
    }
  },
  {
    path: '/chapterSonList',
    name: 'chapterSonList',
    component: () => import('@/views/chapter/chapterson-list'),
    meta: {
      title: '子剧情',
      requiresAuth: true
    }
  },
  {
    path: '/chapterManage',
    name: 'chapterManage',
    component: () => import('@/views/chapter/chapter-manage'),
    meta: {
      title: '章节管理',
      requiresAuth: true
    }
  },
  {
    path: '/chapterBg',
    name: 'chapterBg',
    component: () => import('@/views/chapter/chapter-bg'),
    meta: {
      title: '背景库',
      requiresAuth: true
    }
  },
  {
    path: '/chapterBgm',
    name: 'chapterBgm',
    component: () => import('@/views/chapter/chapter-bgm'),
    meta: {
      title: '背景音乐',
      requiresAuth: true
    }
  },
  {
    path: '/chapterCg',
    name: 'chapterCg',
    component: () => import('@/views/chapter/chapter-cg'),
    meta: {
      title: 'CG库',
      requiresAuth: true
    }
  },
  {
    path: '/chapterLook',
    name: 'chapterLook',
    component: () => import('@/views/chapter/chapter-look'),
    meta: {
      title: '预览',
      requiresAuth: false
    }
  },
  {
    path: '/roleManage',
    name: 'roleManage',
    component: () => import('@/views/chapter/role-manage'),
    meta: {
      title: '角色管理',
      requiresAuth: true
    }
  },
  {
    path: '/roleAdd',
    name: 'roleAdd',
    component: () => import('@/views/chapter/role-add'),
    meta: {
      title: '添加角色',
      requiresAuth: true
    }
  },
  {
    path: '/roleList',
    name: 'roleList',
    component: () => import('@/views/chapter/role-list'),
    meta: {
      title: '选择形象',
      requiresAuth: true
    }
  }
]
