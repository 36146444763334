import Vue from 'vue'
import Vuex from 'vuex'
import home from './module/home'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    name: '2222',
    bookInfo: { // 书籍边界页 ---  创建书籍信息（选择图书封面保存作品信息）
      bookId: '',
      bookName: '',
      sumWords: '',
      bookIntro: '',
      cover: {
        coverId: '',
        coverImg: ''
      },
      startChapterName: ''
    },
    chapterInfo: { // 章节编辑页 --- 保存章节内容编辑数据
      chapterName: '',
      chapterBg: {},
      chapterBgm: {},
      chapterCg: {},
      chapterCgIndex: -1,
      showType: -1,
      chapterType: -1,
      roleList: []
    },
    sltCptBgActive: { // 章节编辑页 -- 保存背景编辑数据
      firstTypeIndex: '',
      firstTypeId: '',
      subTypeIndex: '',
      subTypeId: '',
      listActive: ''
    },
    sltCptBgmActive: { // 章节编辑页 -- 保存背景音乐编辑数据
      firstTypeIndex: '',
      firstTypeId: '',
      subTypeIndex: '',
      subTypeId: '',
      listActive: ''
    },
    sltCptCgActive: { // 章节编辑页 -- 保存Cg编辑数据
      firstTypeIndex: '',
      firstTypeId: '',
      subTypeIndex: '',
      subTypeId: '',
      listActive: ''
    },
    // chapterSonEdit: { // 章节编辑页 -- 保存背景音乐编辑数据
    //   subIndex: '',
    //   ugcSubChapter: {
    //     subChapterId: '',
    //     subChapterName: ''
    //   }
    // },
    chapterSonEdit: [], // 章节编辑页 -- 保存子章节编辑数据
    sltRoleActive: { // 角色添加页 ---- 选择角色形象的数据
      firstTypeIndex: '',
      firstTypeId: '',
      subTypeIndex: '',
      subTypeId: '',
      listActive: ''
    },
    roleInfo: { // 角色添加页 ----- 角色编辑的数据
      roleName: '',
      roleNickname: '',
      roleImg: '',
      roleIntro: ''
    },
    bookRoleEditId: '',
    roleEdit: []
  },
  mutations: {},
  modules: {
    home
  }
})
