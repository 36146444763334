export default [
  {
    path: '/vuex',
    name: 'demoVuex',
    component: () => import('@/views/demo-vuex'),
    meta: {
      title: 'demoVuex'
    }
  }
]
