export default [
  {
    path: '/create',
    name: 'create',
    component: () => import('@/views/create'),
    meta: {
      title: '创作',
      requiresAuth: true
    },
    children: [
      {
        path: '/book',
        name: 'book',
        component: () => import('@/views/create/create-book'),
        meta: {
          title: '创作'
        }
      },
      {
        path: '/course',
        name: 'course',
        component: () => import('@/views/create/create-course'),
        meta: {
          title: '公约'
        }
      }
    ]
  }
]
