
export default [
  {
    path: '/axios',
    name: 'demoAxios',
    component: () => import('@/views/demo-axios'),
    meta: {
      title: 'demoAxios'
    }
  }
]
